import { useState, useEffect } from "react"
import type { ComponentType } from "react"

export function CMSContentImageLightboxWithSmoothTransition(
    Component
): ComponentType {
    return (props) => {
        const [lightboxDisplay, setLightboxDisplay] = useState(false)
        const [selectedImage, setSelectedImage] = useState("")
        const [opacity, setOpacity] = useState(0)

        const handleImageClick = (src) => {
            setSelectedImage(src)
            setLightboxDisplay(true)
        }

        useEffect(() => {
            if (lightboxDisplay) {
                setTimeout(() => setOpacity(1), 10)
            }
        }, [lightboxDisplay])

        const closeLightbox = () => {
            setOpacity(0)
            setTimeout(() => setLightboxDisplay(false), 300)
        }

        return (
            <>
                <Component
                    {...props}
                    onClick={(e) => {
                        if (e.target.tagName === "IMG") {
                            handleImageClick(e.target.src)
                        }
                    }}
                    style={{ cursor: "pointer" }}
                />
                <style>{`
                    .${props.className} img {
                        cursor: zoom-in;
                    }
                    .lightbox-backdrop {
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100vw;
                        height: 100vh;
                        background-color: rgba(0, 0, 0, 0.8);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        z-index: 1000;
                        opacity: 0;
                        transition: opacity 0.3s ease;
                    }
                    .lightbox-image {
                        max-height: 90%;
                        max-width: 90%;
                    }
                `}</style>
                {lightboxDisplay && (
                    <div
                        className="lightbox-backdrop"
                        onClick={closeLightbox}
                        style={{ opacity: opacity }}
                    >
                        <img
                            src={selectedImage}
                            className="lightbox-image"
                            alt="Fullscreen"
                        />
                    </div>
                )}
            </>
        )
    }
}
